.home-main-container {
  font-family: "Poppins", sans-serif;
}
.home-carousel-content {
  max-width: 100%;
  /* height: 200px !important; */
}
.carousel-image {
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire area while maintaining aspect ratio */
  object-position: center; /* Center the image within the container */
}
.home-about-company {
  width: 100%;
  padding: 2rem;
  background-color: #f5f5f5;
  padding-bottom: 4rem;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.2); */
}
.home-about-us-button {
  background: #2b3579 !important;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 0rem !important;
  margin-top: 2rem;
  border: none !important;
  width: 50%;
}
.home-about-company-content {
  align-content: center;
  text-align: left;
}
.home-about-company-content-text {
  text-align: justify;
}
/* 
.home-who-are-we-text h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: #2b3579;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
}

.home-who-are-we-text h1:after,
.home-who-are-we-text h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #2c2e35;
  border-top: 2px solid #2c2e35;
  height: 10px;
  background-color: #f8f8f8;
} */

.home-page-about-image {
  border-left: 0.15rem solid #cecece;
  padding: 2rem;
}

.about-us-content-img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.2);
  /* border-radius: 2rem; */
  /* padding: 1rem; */
  height: auto;
  max-width: 100%;
  width: 80%;
}
.home-company-flip-cards {
  background-color: white;
  align-items: center;
  justify-content: space-around;
  margin: 3rem 0;
}
.flip-card {
  margin: 3rem 4rem;
  background-color: transparent;
  width: 250px;
  height: 220px;
  perspective: 1000px;
  font-family: sans-serif;
}

.flip-card-title {
  font-size: 1.5em;
  font-weight: 700;
  text-align: center;
  margin: 0;
}
.flip-card-text {
  padding: 1.5rem;
  text-align: center;
}
.home-company-stats {
  display: flex;
  flex-direction: row;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  /* box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2); */
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* border: 1px solid #2b3579; */
  /* box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */

  /* border-radius: 0.5rem; */
}

.flip-card-front {
  /* background: linear-gradient(120deg, #F5F5F5 60%, #fff0c2  88%,
    #f5ce5b  40%, #FFCC33 48%); */
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.2);
  background: #e9e9e9;
  color: #000;
  font-family: "Poppins", sans-serif;
}

.flip-card-back {
  font-family: "Poppins", sans-serif;
  border-radius: 0.25rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.2);
  /* background: linear-gradient(120deg, rgb(255, 174, 145) 30%, coral 88%,
       bisque 40%, rgb(255, 185, 160) 78%); */
  background: #2b3579;
  color: #fff;
  transform: rotateY(180deg);
}
.home-join-us {
  background-color: #ffcc33;
  padding: 2rem;
}

.home-about-safety {
  background-color: #ffcc33;
  padding: 4rem 0rem;
  border-radius: 1rem;
  margin: 3rem 1rem 3rem 1rem;
}
.home-about-safety p {
  padding: 0rem 4rem;
}
.home-main-title-text h1 {
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  color: #2b3579;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 27px 0;
  grid-gap: 20px;
  align-items: center;
}
.home-main-title-text h1:after,
.home-main-title-text h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #2c2e35;
  border-top: 2px solid #2c2e35;
  height: 10px;
  background-color: #ffcc33;
}
.home-join-us {
  background-color: #ffcc33;
  padding: 2rem 1rem;
  border-radius: 1rem;
  margin: 3rem 1rem 3rem 1rem;
}
li {
  list-style: none;
}
.home-about-services {
  width: 100%;
  padding: 2rem;
  background-color: #f5f5f5;
  margin-top: 3rem 0;
}
.home-about-service-list {
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  color: #fff;
  background-color: #2b3579;
}
.home-about-service-title h2 {
  font-size: 2.5rem;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 0.4rem;
  position: relative;
  color: #2b3579;
}
.home-about-service-title h2:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #ffbf00;
  z-index: 1;
}
.home-about-service-title h2:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}
.join-us-content-main {
  align-items: center;
}
.join-us-content-text {
  text-align: justify;
}
.join-us-content-image {
  height: 25rem;
}
.join-us-content-image img {
  /* object-fit: contain; */
  height: 25rem;
}
.join-us-content-img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 2rem;
  /* padding: 1rem; */
}
.join-us-content-divider {
  width: 100%;
}
.services-provided-card {
  width: 100%;
  max-width: 300px;
  min-width: 200px;
  height: 250px;
  background-color: #2b3579;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.24);
  border: 2px solid rgba(7, 7, 7, 0.12);
  font-size: 16px;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
}

.services-provided-icon {
  margin: 0 auto;
  width: 100%;
  height: 100px;
  max-width: 100px;
  background: #ffcc33;
  /* background: linear-gradient(90deg, #FFCC33 0%, #FFCC33 40%, rgba(255, 255, 255, 0.28) 60%); */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.8s ease;
  background-position: 0px;
  background-size: 200px;
}

.services-provided-card .services-provided-title {
  width: 100%;
  margin: 0;
  text-align: center;
  margin-top: 30px;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 4px;
}

.services-provided-card .services-provided-text {
  width: 80%;
  margin: 0 auto;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  color: white;
  font-weight: 200;
  letter-spacing: 2px;
  opacity: 0;
  max-height: 0;
  transition: all 0.3s ease;
}

.services-provided-card:hover {
  height: 270px;
}

.services-provided-card:hover .services-provided-text {
  transition: all 0.3s ease;
  opacity: 1;
  max-height: 40px;
}

.services-provided-card:hover .services-provided-icon {
  background-position: -120px;
  transition: all 0.3s ease;
}
.services-provided-card:hover .services-provided-icon img {
  fill: url("#gradientColor");
  transition: all 0.3s ease;
}
.services-provided-icon img {
  fill: white;
}

.home-page-features-item {
  background-color: #2b3579;
  padding: 70px 20px 10px;
  height: 9rem;
  position: relative;
  transition: 0.3s;
  margin-top: 5rem;
  border-radius: 1rem;
  &:hover {
    box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22);
    cursor: pointer;
    .home-page-features-icon {
      color: #ffcc33;
      border-color: #2b3579;
    }
    .home-page-features-info {
      .home-page-feature-title {
        color: #fff;
        border-color: #2b3579;
      }
      p {
        color: #fff;
        border-color: #2b3579;
      }
    }
  }
}
.home-page-features-icon {
  font-size: 2rem;
  width: 96px;
  height: 96px;
  /* line-height: 89px; */
  text-align: center;
  align-content: center;
  background: #ffcc33;
  border-radius: 60px;
  /* border: 2px dashed #e6e6e6; */
  /* box-shadow: 0 0 0 10px white; */
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%);
  color: #bdbdbd;
  transition: 0.3s;
}
.home-page-features-info .home-page-feature-title {
  color: #fff;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  letter-spacing: 4px;
}
.home-page-features-item:hover {
  transform: scale(1.05); /* Slightly scale up the card */
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
  cursor: pointer;
}
.home-page-features-item:hover .home-page-features-icon {
  background: #ffcc33; /* Icon background changes on hover */
  transform: translate(-50%) scale(1.2); /* Slightly scale up the icon */
}
.home-page-features-item:hover
  .home-page-features-info
  .home-page-feature-title {
  color: #ffcc33; /* Title color changes on hover */
  transform: translateY(-5px); /* Slightly lift the title on hover */
}

.home-page-features-item:hover .home-page-features-info p {
  color: #ddd; /* Optional: change paragraph color on hover */
}

/* Optional: Add an overlay gradient effect */
.home-page-features-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
  transition: opacity 0.3s;
  opacity: 0; /* Hidden by default */
  z-index: 0; /* Ensure it's below the content */
  border-radius: 1rem;
}

.home-page-features-item:hover::before {
  opacity: 1; /* Show overlay on hover */
}
.home-more-service-button {
  background: #2b3579 !important;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 0rem !important;
  margin-top: 5rem;
  margin-bottom: 3rem;
  border: none !important;
  width: 30%;
}
.client-slider {
  text-align: center;
  padding: 3rem 0;
}

.client-slider h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  /* color: #333; */
  font-weight: 600;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0.5rem;
  position: relative;
  color: #2b3579;
}

.client-slider h2:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 10rem;
  background-color: #ffbf00;
  left: 50%;
  transform: translateX(-50%);
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 150px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: inline-block;
  animation: 15s slide infinite linear;
}

.logos-slide img {
  height: 5rem;
  margin: 0 40px;
}
.home-safety-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Adjust the padding if necessary */
  text-align: left;
}

.home-safety-img {
  height: auto;
  max-width: 100%;
  width: 60%;
  border-radius: 25px;
  box-shadow: 0rem 1rem 3rem rgba(0, 0, 0, 0.5) !important;
}
.home-safety-content h2 {
  font-size: 45px;
  font-weight: 400;
  margin-bottom: 1rem;
  text-transform: uppercase;
  color: #2b3579;
}
.home-safety-content p {
  line-height: 24px;
  text-align: justify;
  font-size: 16px;
  /* padding-right: 10rem; */
}
.home-joinus-img {
  height: auto;
  max-width: 100%;
  width: 100%;
  /* border-radius: 25px; */
  box-shadow: 0rem 1rem 3rem rgba(0, 0, 0, 0.5) !important;
}
.home-joinus-main {
  background-color: #ffcc33;
  padding: 3rem 2rem;
  /* border-radius: 1rem; */
  margin: 3rem 0rem 3rem 0rem;
}
.home-safety-main {
  margin: 5rem 0;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.home-page-main-button {
  margin-top: 2rem;
  width: 17rem;
  height: auto;
}

button.home-page-main-button .home-button-circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background: #2b3579;
  border-radius: 1.625rem;
}

button.home-page-main-button .home-button-circle .home-button-icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.home-page-main-button
  .home-button-circle
  .home-button-icon.home-button-arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.home-page-main-button
  .home-button-circle
  .home-button-icon.home-button-arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.home-page-main-button .home-main-button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: #282936;
  font-weight: 700;
  line-height: 1.6;
  text-align: center;
  text-transform: uppercase;
}

button:hover .home-button-circle {
  width: 100%;
}

button:hover .home-button-circle .home-button-icon.home-button-arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

button:hover .home-main-button-text {
  color: #fff;
}
.home-about-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  text-align: left;
}
.home-about-content h2 {
  font-size: 45px;
  font-weight: 400;
  margin-bottom: 2rem;
  text-transform: uppercase;
  color: #2b3579;
}
.home-about-content p {
  line-height: 24px;
  text-align: justify;
  font-size: 16px;
  padding-right: 2rem;
}

.home-who-are-we-text {
  text-align: center;
  padding: 1rem 0;
}

.home-who-are-we-text h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  /* color: #333; */
  font-weight: 600;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0.5rem;
  position: relative;
  color: #2b3579;
}

.home-who-are-we-text h2:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 10rem;
  background-color: #ffbf00;
  left: 50%;
  transform: translateX(-50%);
}
/* Mobile Styles */
@media only screen and (max-width: 767px) {
  .home-main-container {
    font-size: 14px;
  }

  .home-carousel-content {
    height: auto; /* Allow carousel content to adapt */
  }

  .carousel-image {
    height: auto; /* Adjust image height for mobile */
  }

  .home-about-company {
    padding: 1rem; /* Adjust padding for mobile */
    box-shadow: none; /* Remove shadow on mobile */
  }

  .home-about-us-button {
    width: 100%; /* Full width for mobile */
    padding: 1rem; /* Adjust padding for mobile */
  }

  .home-about-company-content {
    text-align: center; /* Center text on smaller screens */
  }

  .home-about-company-content-text {
    text-align: left; /* Keep text justified */
  }

  .home-page-about-image {
    border-left: none; /* Remove left border for mobile */
    padding: 1rem; /* Adjust padding for mobile */
  }

  .about-us-content-img {
    width: 100%; /* Full width for mobile */
    max-width: 100%; /* Ensure image doesn't exceed container */
  }

  .home-company-flip-cards {
    flex-direction: column; /* Stack flip cards vertically */
    align-items: center; /* Center align the flip cards */
    justify-content: center;
  }

  .home-join-us,
  .home-about-safety {
    padding: 1rem; /* Adjust padding for mobile */
    margin: 1rem 0; /* Reduce margins */
  }

  .home-main-title-text h1 {
    font-size: 1.5rem; /* Smaller font size */
  }

  .home-about-services {
    padding: 1rem; /* Adjust padding for mobile */
  }

  .home-about-service-list {
    padding: 0.5rem; /* Adjust padding for mobile */
    font-size: 14px; /* Adjust font size */
  }

  .home-about-service-title h2 {
    font-size: 1.5rem; /* Smaller font size */
  }

  .join-us-content-image {
    height: auto; /* Adjust image height */
    width: 100%; /* Full width for mobile */
  }

  .services-provided-card {
    width: 100%; /* Full width for mobile */
    height: auto; /* Adjust height */
    margin: 10px 0; /* Adjust margin */
  }

  .home-page-features-item {
    padding: 30px 10px; /* Adjust padding for mobile */
    height: auto; /* Adjust height */
  }

  .home-page-features-icon {
    width: 70px; /* Smaller icon for mobile */
    height: 70px; /* Smaller icon for mobile */
  }

  .client-slider h2 {
    font-size: 1.5rem; /* Smaller font size */
  }

  .logos-slide img {
    height: 4rem; /* Adjust logo height */
    margin: 0 20px; /* Adjust margins */
  }

  .home-safety-content,
  .home-about-content {
    padding: 1rem; /* Adjust padding */
  }
  .home-about-content h2 {
    font-size: 2.5rem;
  }
  .about-us-content-img-container {
    border-left: none !important;
  }
  .home-joinus-img {
    width: 100%; /* Full width for mobile */
    height: auto; /* Adjust height */
  }
  .home-safety-img {
    width: 60%; /* Full width for mobile */
    height: auto; /* Adjust height */
    margin-bottom: 2rem;
  }

  .home-page-main-button {
    width: 100%; /* Full width for mobile */
  }
}
