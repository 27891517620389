.service-page-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem 0rem 4rem 0rem;
  background-color: #f5f5f5;
  width: 100%;
}

.service-page-main-title h1 {
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 0.4rem;
  position: relative;
  color: #2b3579;
  font-family: "Montserrat", sans-serif;
}
.service-page-main-title h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #ffbf00;
  z-index: 1;
}
.service-page-main-title h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}
.service-page-contact-container {
  background-color: #ffcc33;
  width: 100%;
  color: #000000;
  align-items: center;
  justify-content: space-evenly;
}
/* Base button styling */
.btn.btn-primary.service-page-contact-button-text {
  background: #2b3579 !important;
  border: none;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 0rem !important;
}

/* Hover state */
.btn.btn-primary.service-page-contact-button-text:hover {
  transform: translateY(-0.1rem);
}

.service-page-content-main-container {
  padding: 1rem 3rem 3rem 3rem;
  /* background-color: #f9f9f9; */
  height: auto;
}

.service-page-right-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background: #dddddd;
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.service-page-left-container {
  padding: 2rem;
  /* border-radius: 0.5rem; */
  background: rgb(202, 202, 202);
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.service-page-left-container h2 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  /* color: #333; */
  font-weight: 500;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0.5rem;
  position: relative;
  color: #2b3579;
}

.service-page-left-container h2:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 10rem;
  background-color: #ffbf00;
  left: 50%;
  transform: translateX(-50%);
}

.service-page-left-container p {
  text-align: justify;
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

/*  Alternate Container*/

.service-page-alt-left-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  background: rgb(202, 202, 202);

  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.service-page-alt-right-container h2 {
  font-size: 1.7rem;
  margin-bottom: 1rem;
  /* color: #333; */
  font-weight: 500;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0.5rem;
  position: relative;
  color: #2b3579;
}

.service-page-alt-right-container h2:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 10rem;
  background-color: #ffbf00;
  left: 50%;
  transform: translateX(-50%);
}

.service-page-alt-right-container p {
  text-align: justify;
  font-size: 1rem;
  color: #333;
  line-height: 1.6;
}

.service-page-alt-right-container {
  padding: 2rem;
  /* border-radius: 0.5rem; */
  background: #dddddd;

  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}

/* Image Carousel */

.service-page-main-carousel .carousel-item {
  border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem;
  /* overflow: hidden; */
}

.service-page-carousel-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem; */
}

.service-page-carousel-image {
  height: 100%;
  /* border-top-right-radius: 4rem;
  border-bottom-right-radius: 4rem; */
  object-fit: cover; /* Ensures the image covers the entire area while maintaining aspect ratio */
  object-position: center; /* Center the image within the container */
}

/* alternate image */
.service-page-carousel-alt-image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem; */
}

.service-page-carousel-alt-image {
  height: 100%;
  /* border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem; */
  object-fit: cover; /* Ensures the image covers the entire area while maintaining aspect ratio */
  object-position: center; /* Center the image within the container */
}
/*  Breadcrumb Styles */
.custom-breadcrumb {
  padding-left: 3rem;
}

.custom-breadcrumb .breadcrumb-item a {
  font-size: 0.8rem;
  color: #343a40;
  text-decoration: none;
}

.custom-breadcrumb .breadcrumb-item.active a {
  color: #343a40;
  font-weight: bold;
}
.breadcrumb-item {
  + .breadcrumb-item::before {
    content: ">" !important;
    align-self: baseline;
    color: #7d8daa;
  }
}
.horizontal-divider {
  height: 1.5px;
  width: 100%;
  margin-bottom: 1.5rem;
  background-image: linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
  background-image: -o-linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
  background-image: -moz-linear-gradient(
    left,
    white 2%,
    #e6e6e6 50%,
    white 98%
  );
  background-image: -webkit-linear-gradient(
    left,
    white 2%,
    #e6e6e6 50%,
    white 98%
  );
  background-image: -ms-linear-gradient(left, white 2%, #e6e6e6 50%, white 98%);
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right bottom,
    color-stop(0.02, white),
    color-stop(0.5, rgb(155, 155, 155)),
    color-stop(0.98, white)
  );
}

.list-container {
  display: flex;
  justify-content: space-between;
}

.list-column {
  width: 45%; /* Adjust as needed */
}

.list-column li {
  list-style-type: disc; 
  margin-bottom: 3px; /* Adjust spacing between items as needed */
  text-align: left;
}
.single-list-column {
  width: 100%; /* Adjust as needed */
}

.single-list-column li {
  list-style-type: disc; 
  margin-bottom: 3px; /* Adjust spacing between items as needed */
  text-align: left;
}
@media (max-width: 767px) {
  .service-page-main-container {
    padding: 1rem;
  }

  .service-page-main-title h1 {
    font-size: 28px;
    text-align: center;
  }

  .service-page-content-main-container {
    flex-direction: column;
    padding: 1rem;
  }

  .service-page-left-container,
  .service-page-right-container,
  .service-page-alt-left-container,
  .service-page-alt-right-container {
    flex-direction: column;
    padding: 1rem;
    box-shadow: none;
    border-radius: 0;
    background-color: #f5f5f5;
  }

  .service-page-left-container h2,
  .service-page-alt-right-container h2 {
    font-size: 1rem;
    font-weight: 600;
  }

  .service-page-carousel-image-wrapper,
  .service-page-carousel-alt-image-wrapper {
    border-radius: 0;
  }

  .service-page-carousel-image,
  .service-page-carousel-alt-image {
    height: auto;
  }

  .horizontal-divider {
    margin-bottom: 1rem;
  }

  .custom-breadcrumb {
    padding-left: 0.5rem;
  }

  .service-page-contact-container {
    flex-direction: column;
    text-align: center;
    padding: 1rem;
  }

  .service-page-contact-button {
    margin-top: 1rem;
  }

  .list-container {
    flex-direction: column;
  }

  .list-column,
  .single-list-column {
    width: 100%;
  }

  .service-page-left-container h2:before,
  .service-page-alt-right-container h2:before {
    width: 6rem;
  }
}
@media only screen and (min-width: 1400px) {
  .service-page-main-container{
  padding-left: 9rem;
  padding-right: 9rem;
}
}