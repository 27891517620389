/* .navbar {
    background-color: #fff; 
    padding: 10px; 
    border-bottom: 1px solid #dee2e6; 
  }
  
.nav-link {
color: #000 !important; 
margin-right: 10px;
font-weight: bold; 
}

.nav-link:hover {
color: #007bff !important; 
}

.nav-link.active {
    color: rgb(255, 153, 0) !important;
    border: 1px solid green !important;
}

.nav-item {
margin-right: 10px; 
}
.nav-underline .nav-link.active{
    color: rgb(255, 153, 0) !important;
} */
/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,500;1,400&display=swap"); */

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */



li {
  list-style: none;
}

.header {
  /* border-bottom: 1px solid #e2e8f0; */
}

.navbar {
    /* background-color: #2ff7e6; */
    /* font-size: 1rem; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  font-family: "Montserrat", sans-serif;

}

.hamburger {
  display: none;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-color: #101010;
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 10px; */
  /* background-color: #482ff7; */
}

.nav-item {
  margin-left: 3rem;
}

.nav-link {
  /* font-size: 1.6rem; */
  font-weight: 400;
  color: #475569;
}

.nav-item:hover {
  color: #FFCC33;
}

.nav-logo {
  /* font-size: 2.1rem; */
  font-weight: 500;
  color: #482ff7;
}
.nav-link.active {
  position: relative;
  color: #2b3579 !important; /* Change the text color to yellow */
}

.nav-link.active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background-color: #FFCC33; /* Yellow underline */
  position: absolute;
  bottom: -2px;
  left: 0;
}
@media only screen and (max-width: 870px) {
  .nav-menu {
    position: absolute; /* Change from 'fixed' to 'absolute' */
    left: -675%; /* Aligns the menu to the left side */
    top: 3rem; /* Places the menu just below the header */
    flex-direction: column;
    background-color: #000000;
    width: 100vw;
    text-align: center;
    transition: 0.3s;
    box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
    z-index: 10;
    opacity: 0.9;
    color: white;
visibility: hidden;

  }
  
  /* .nav-menu.active {
    left: 0;
  } */
  .bar{
    margin-right: 0rem;
    margin-left: 1.4rem;
    margin-top: 0px;
    align-items: center;
  }
  .nav-menu.active {
visibility: visible;
  }
  .nav-link.active {
    color: #FFCC33 !important; /* Change the text color to yellow */
  }
  
  .nav-link.active::after {
    background-color: #fff; /* Yellow underline */

  }
  .nav-item {
    margin: 0.7rem 0;
    /* border-bottom: solid 0.2rem #e9e9e9; */

  }
  
  .hamburger {
    display: block;
    cursor: pointer;
  }

  .hamburger.active .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
