/* @import url('https://fonts.googleapis.com/css2?family=Trivial:wght@400;700&display=swap'); */
@import url('https://fonts.cdnfonts.com/css/trivial');
.header-main-container{
    background-color: #fff;
    /* position: sticky; */
    /* background-color: #2b3579; */
    /* color: white; */
    top: 0;
    /* z-index: 100; */
    display: flex;
    justify-content: space-between;
    /* align-items: baseline; */
    /* padding: 0.5rem; */
    border-bottom: 0.5px solid rgba(128, 128, 128, 0.267);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    
}
.header-logo-container{
    display: flex;
}
.header-navbar-container{
    margin-right: 2rem;
    display: flex;
    align-items: center;
}
.header-logo-text{
font-size:2rem;
margin-left: 10px;
/* font-weight: 600; */
font-family: 'Trivial', sans-serif;
}
.header-logo-image img{
    /* font-size:2rem; */
    width: 3rem;
    height: 3rem;
    /* margin-left: 10px; */
}
@media only screen and (max-width: 870px) {
    .header-main-container{
        display: flex;
        padding-bottom: 0.8rem ;
        justify-content:  space-between;
        /* align-items: center; */
        background-color: #fff;
    }
    .header-logo-text{
        /* background-color: aquamarine; */
        font-size:1.9rem;
        display: flex;
        align-items: center;
        margin-top: 5px;
        }
    .header-logo-container{
        /* background-color: blueviolet; */
    }
    .header-logo-image{
        /* background-color: blue; */
        }
}
