.services-main-container{
    /* background-color:  #186cb111; */
    background-color:  #F5F5F5;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 2rem;
    /* padding-left: 10%;
    padding-right: 10%; */
}
.service-main-content{
    padding-top: 2rem;

}

.services-main-title h1 {
    font-size: 36px;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0;
    line-height: 1.5em;
    padding-bottom: 10px;
    position: relative;
    color: #2b3579;

  }
  .services-main-title h1:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: #FFBF00;
    z-index: 1;
  }
  .services-main-title h1:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 95%;
    max-width: 255px;
    background-color: #333;
  }
  
.service-card {
    position: relative;
    margin-bottom: 3rem;
    overflow: hidden;
    margin-left: 1rem;
    margin-right: 1rem;
    box-shadow: 0 8px 35px 0 rgba(0,0,0,.5), 0 0 1px 0 rgba(0,0,0,.65);
  border-radius: 1rem;
  }
  
  .card-image {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .card-image img {
    width: 100%;
    height:15.6rem;
    object-fit: cover;
  }
  
  .service-details {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 70%, rgba(0, 0, 0, 0) 100%);
    padding: 10px;
    box-sizing: border-box;
    transform: translateY(50%); /* Initially hide details */
    transition: transform 0.5s;
  }
  
  .service-name {
    margin: 0;
    color: white;
    font-size: 17px;
    font-weight: 600;
    padding-bottom: 5px;
  }
  
  .service-description {
    margin: 10px 0 20px;
    color: white;
    font-size: 1em;
  }
  
  .read-more-btn {
    color: white;
    text-decoration: none;
    padding: 2px 4px;
    border: 1px solid white;
    border-radius: 4px;
    font-size: 15px;
  }
  .service-card:hover{
    /* transform: translateY(-8px); */
      box-shadow: 0 8px 25px 0 rgba(0,0,0,.3), 0 0 1px 0 rgba(0,0,0,.65);
  }
  .service-card:hover .service-details {
    transform: translateY(0); /* Show details on hover */
  }
  .image-container{
    width: 100%;
    height: 100%;
  }
  .image-container  img{
    object-fit: cover;
  }
  .loader {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    --color: hsl(0, 0%, 87%);
    --animation: 2s ease-in-out infinite;
  }
  
  .loader .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 20px;
    height: 20px;
    border: solid 2px var(--color);
    border-radius: 50%;
    margin: 0 10px;
    background-color: transparent;
    animation: circle-keys var(--animation);
  }
  
  .loader .circle .dot {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--color);
    animation: dot-keys var(--animation);
  }
  
  .loader .circle .outline {
    position: absolute;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    animation: outline-keys var(--animation);
  }
  
  .circle:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .circle:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .circle:nth-child(4) {
    animation-delay: 0.9s;
  }
  
  .circle:nth-child(5) {
    animation-delay: 1.2s;
  }
  
  .circle:nth-child(2) .dot {
    animation-delay: 0.3s;
  }
  
  .circle:nth-child(3) .dot {
    animation-delay: 0.6s;
  }
  
  .circle:nth-child(4) .dot {
    animation-delay: 0.9s;
  }
  
  .circle:nth-child(5) .dot {
    animation-delay: 1.2s;
  }
  
  .circle:nth-child(1) .outline {
    animation-delay: 0.9s;
  }
  
  .circle:nth-child(2) .outline {
    animation-delay: 1.2s;
  }
  
  .circle:nth-child(3) .outline {
    animation-delay: 1.5s;
  }
  
  .circle:nth-child(4) .outline {
    animation-delay: 1.8s;
  }
  
  .circle:nth-child(5) .outline {
    animation-delay: 2.1s;
  }
  
  @keyframes circle-keys {
    0% {
      transform: scale(1);
      opacity: 1;
    }
  
    50% {
      transform: scale(1.5);
      opacity: 0.5;
    }
  
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes dot-keys {
    0% {
      transform: scale(1);
    }
  
    50% {
      transform: scale(0);
    }
  
    100% {
      transform: scale(1);
    }
  }
  
  @keyframes outline-keys {
    0% {
      transform: scale(0);
      outline: solid 20px var(--color);
      outline-offset: 0;
      opacity: 1;
    }
  
    100% {
      transform: scale(1);
      outline: solid 0 transparent;
      outline-offset: 20px;
      opacity: 0;
    }
  }

  @media only screen and (min-width: 1400px) {
    .services-main-container{
      padding-left: 10%;
      padding-right: 10%;
  }
  .card-image img {
    height:17rem;
  }
  }
  @media only screen and (max-width: 767px) {
    .services-main-container {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1rem;
    }
  
    .services-main-title h1 {
      font-size: 28px;
      text-align: left;
    }
  
    .services-main-title h1:before {
      height: 4px;
      width: 45px;
    }
  
    .services-main-title h1:after {
      max-width: 200px;
    }
  
    .service-card {
      margin-bottom: 2rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
  
    .card-image img {
      height: 13rem;
    }
  
    .service-details {
      padding: 8px;
    }
  
    .service-name {
      font-size: 15px;
    }
  
    .read-more-btn {
      font-size: 14px;
    }
  
    .loader {
      height: 70vh;
    }
  
    .loader .circle {
      width: 16px;
      height: 16px;
    }
  
    .loader .circle .dot {
      width: 12px;
      height: 12px;
    }
  
    .loader .circle .outline {
      width: 16px;
      height: 16px;
    }
  }
  