.about-us-main-container{
    background-color: #aaaaaa25;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
}
.about-us-content-main{
    text-align: left;
}
.about-policy-main{
    text-align: left;
}
.about-us-content, .about-policy-content{
    text-align: justify;
}
.about-us-header h1 {
    font-size: 36px;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0;
    line-height: 1.5em;
    padding-bottom: 2px;
    font-family: "Montserrat", sans-serif;
    color: #2b3579;

    position: relative;
  }
  .about-us-header h1:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: #FFBF00;
    z-index: 1;
  }
  .about-us-header h1:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 95%;
    max-width: 255px;
    background-color: #000000;
  }
  .about-us-subcontent-header h2 {
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0;
    line-height: 1.5em;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 4px;
    position: relative;
    color: #2b3579;
  }
  .about-us-subcontent-header h2:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: #FFBF00;
  }
  .about-carousel-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 2rem;    
  }
  .about-carousel-policy-image-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 0rem;    
  }
  
  .about-carousel-image {
    height: 100%;
    object-fit: cover; /* Ensures the image covers the entire area while maintaining aspect ratio */
    object-position: center; /* Center the image within the container */
  }
  .about-page-right-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    border-left: 0.15rem solid #cecece ;
  }
  .about-page-left-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 2rem;
  }
  /* .about-comapny-heading h2:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 95%;
    max-width: 255px;
    background-color: #333;
  } */
  .about-policy-header h1 {
    font-size: 36px;
    font-weight: 600;
    text-align: left;
    letter-spacing: 0;
    line-height: 1.5em;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 2px;
    position: relative;
    color: #2b3579;

  }
  .about-policy-header h1:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: #FFBF00;
    z-index: 1;
  }
  .about-policy-header h1:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 2px;
    height: 1px;
    width: 95%;
    max-width: 255px;
    background-color: #333;
  }
  .about-policy-subheader h2 {
    font-size: 28px;
    font-weight: 500;
    text-align: left;
    letter-spacing: 0;
    line-height: 1.5em;
    padding-bottom: 4px;
    font-family: "Montserrat", sans-serif;
    position: relative;
    color: #2b3579;

  }
  .about-policy-subheader h2:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 5px;
    width: 55px;
    background-color: #FFBF00;
  }
  
  .about-policy-content >ul > li{
    list-style-type: disc;
  }
  .vision-title h2 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #2b3579;
    margin-bottom: 0.5rem;
    text-align: center;
    letter-spacing: 0;
    line-height: 1.5em;
    font-family: "Montserrat", sans-serif;
    padding-bottom: 0.2rem;
    position: relative;
  }
  .vision-title h2:before {
    content: "";
    position: absolute;
    left:35%;
    bottom: 0;
    height: 3px;
    width: 5rem;
    background-color: #FFBF00;
  }
  .vision-container-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 2rem;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    padding: 1.5rem;
    margin: 1rem;
    max-width: 20rem;
    height: 27rem;
  }
  .vision-container-main:hover {
    /* background-color: #FFCC33; */
    transform: translateY(-0.1rem);
    box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22);
    .vision-icon{
      color: #FFCC33;
      background-color:  #2b3579;

    }
  }
  .vision-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e9e9e9;
    border-radius: 50%;
    width: 4rem;
    height: 4rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #555;

  }

  
  .vision-info {
    text-align: center;
  }
  
  .vision-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #2b3579;
    margin-bottom: 0.5rem;
  }
  
  .vision-content-main {
    font-size: 1rem;
    padding-top: 0.5rem;
    color: #555;
    line-height: 1.6;
  }


  @media only screen and (min-width: 1400px) {
    .about-us-main-container{
      padding-left: 10%;
      padding-right: 10%;
  }
  }
  /* Mobile Styles */
@media only screen and (max-width: 767px) {
  
  .about-us-main-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .about-us-header h1 {
    font-size: 28px;
  }

  .about-us-subcontent-header h2,
  .about-policy-subheader h2 {
    font-size: 22px;
  }

  .about-page-left-container,
  .about-page-right-container {
    padding: 1rem;
    text-align: center;
  }

  .about-page-right-container {
    border-left: none;
    margin-top: 1.5rem;
  }

  .about-carousel-image-wrapper {
    height: 200px; /* Adjust height as needed for mobile */
    padding: 0rem;
  }

  .vision-container-main {
    max-width: 100%;
    height: auto;
    margin: 1rem 0;
  }

  .vision-title h2 {
    font-size: 1.25rem;
  }

  .vision-icon {
    width: 3rem;
    height: 3rem;
    font-size: 1.5rem;
  }

  .vision-content-main {
    font-size: 0.875rem;
  }

  .about-policy-header h1 {
    font-size: 28px;
  }

  .about-policy-content > ul > li {
    font-size: 0.875rem;
  }

  .about-us-content, 
  .about-policy-content {
    font-size: 0.875rem;
  }
  .vision-container-content{
    margin-top: 2rem !important;
  }
}
