.contact-main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 1rem 0rem 0rem 0rem;
  background-color: #f5f5f5;
  width: 100%;
  padding-bottom: 2rem;
}

.contact-main-title h1 {
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 0.4rem;
  position: relative;
  color: #2b3579;
}
.contact-main-title h1:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 55px;
  background-color: #ffbf00;
  z-index: 1;
}
.contact-main-title h1:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 95%;
  max-width: 255px;
  background-color: #333;
}
.contact-main-content {
  /* justify-content: space-around; */
  padding: 0rem 4rem;
}
.contact-main-content-div {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border: 0.5px solid rgb(226, 226, 226);
  border-radius: 2rem;
  width: 100%;
  /* box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */
}

.contact-main-form {
  border-bottom-right-radius: 2rem;
  border-top-right-radius: 2rem;

  width: 50% !important;
  /* border: 2px solid black; */
  /* padding: 1rem; */
  background-color: #fff;
  /* padding: 1rem 3rem 1rem 3rem; */
  border-left: 0.5px solid rgb(226, 226, 226);
  /* border-radius: 1.5rem; */
  color: #000;
  height: 100%;
  /* font-size: 1rem; */
}
.contact-office-map-container {
  /* box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1); */
}
.contact-form-content > input,
textarea,
.form-group {
  text-align: left;
}
.form-group {
  margin-top: 1rem;
}
.form-group > input {
  margin-top: 5px;
  background-color: #f2f2f2;
  font-size: 0.9rem;
}
.form-group > textarea {
  margin-top: 15px;
  background-color: #f2f2f2;
  font-size: 0.9rem;
}
.contact-submit-button {
  margin-top: 2rem;
  background: #2b3579 !important;
  border: none !important;
  color: #fff !important;
  width: 40%;
  /* padding: 1rem 2rem !important; */
  border-radius: 0rem !important;
}
.contact-submit-button:hover {
  transform: translateY(-0.1rem);
}
.contact-brochure-container {
  background-color: #ffcc33;
  width: 100%;
  color: #000000;
  /* padding: 2rem; */
  align-items: center;
  justify-content: space-evenly;
}
/* Base button styling */
.btn.btn-primary.contact-brochure-button-text {
  background: #2b3579 !important;
  border: none;
  color: #fff;
  padding: 1rem 2rem;
  border-radius: 0rem !important;
}

/* Hover state */
.btn.btn-primary.contact-brochure-button-text:hover {
  transform: translateY(-0.1rem);
}

.contact-office-location-content {
  justify-content: left;
}

.home-who-are-we-text h1 {
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: #2b3579;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  grid-template-rows: 17px 0;
  grid-gap: 20px;
  align-items: center;
}

.home-who-are-we-text h1:after,
.home-who-are-we-text h1:before {
  content: " ";
  display: block;
  border-bottom: 2px solid #2c2e35;
  border-top: 2px solid #2c2e35;
  height: 10px;
  background-color: #f8f8f8;
}
.input-container {
  position: relative;
  margin-top: 1.5rem; /* Adjust as necessary for spacing */
}

.placeholder-label {
  position: absolute;
  top: 50%;
  left: 0.5rem; /* Adjust to align with input padding */
  transform: translateY(-50%);
  pointer-events: none; /* Ensure clicks are passed through to the input */
  font-size: 0.9rem;
  color: #949393;
  transition: all 0.2s ease;
}

.input-container input:focus + .placeholder-label,
.input-container input:not(:placeholder-shown) + .placeholder-label {
  top: -0.7rem;
  left: 0.15rem;
  font-size: 0.75rem;
  color: #414141;
}

.input-container textarea:focus + .placeholder-label,
.input-container textarea:not(:placeholder-shown) + .placeholder-label {
  top: -0.7rem;
  left: 0.15rem;
  font-size: 0.75rem;
  color: #414141;
}

.placeholder-label .red-asterisk {
  color: red;
}
.contact-us-subcontent-header h2 {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0;
  line-height: 1.5em;
  font-family: "Montserrat", sans-serif;
  padding-bottom: 0.5rem;
  position: relative;
  color: #2b3579;
}
.contact-us-subcontent-header h2:before {
  content: "";
  position: absolute;
  left: 35%;
  bottom: 0;
  height: 5px;
  width: 10rem;
  background-color: #ffbf00;
}
.text-muted {
  font-size: 0.9rem;
  padding-top: 0.5rem;
}
/* Add these styles to ContactPage.css */

.text-center {
  text-align: center;
}

.contact-details-container {
  background: #f5f5f5;
  border-radius: 0.5rem;
  padding: 4rem;
  margin-top: 4rem;
}

.features-item {
  background-color: #fff;
  padding: 70px 50px 10px;
  height: 11rem;
  position: relative;
  transition: 0.3s;
  border-radius: 1rem;
  &:hover {
    box-shadow: 0 40px 70px 0 rgba(0, 0, 0, 0.22);
    cursor: pointer;
    .features-icon {
      color: #ffcc33;
      border-color: #2b3579;
    }
    .features-info {
      .title {
        color: #2b3579;
        border-color: #2b3579;
      }
      p {
        color: #2b3579;
        border-color: #2b3579;
      }
    }
  }
}
.features-icon {
  font-size: 2rem;
  width: 96px;
  height: 96px;
  line-height: 86px;
  text-align: center;
  background: white;
  border-radius: 60px;
  border: 2px dashed #e6e6e6;
  box-shadow: 0 0 0 10px white;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%);
  color: #bdbdbd;
  transition: 0.3s;
}
.features-info .title {
  color: #2b3579;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 768px) {
  .contact-main-container {
    padding: 1rem 1rem;
  }

  .contact-main-title h1 {
    font-size: 28px;
    text-align: center;
  }

  .contact-main-content {
    padding: 0rem 2rem;
    flex-direction: column;
    align-items: center;
  }

  .contact-main-content-div {
    flex-direction: column;
    border-radius: 1rem;
  }

  .contact-main-form {
    width: 100% !important;
    padding: 2rem 1rem;
    border-radius: 0 0 1rem 1rem;
  }

  .contact-office-location {
    width: 100%;
    height: 300px;
  }

  .contact-brochure-container {
    flex-direction: column;
    padding: 2rem 1rem;
    align-items: center;
  }

  .contact-brochure-text {
    text-align: center;
    margin-bottom: 1rem;
  }

  .contact-brochure-button {
    width: 100%;
    text-align: center;
  }

  .contact-details-container {
    padding: 2rem 1rem;
  }

  .features-item {
    padding: 50px 30px 10px;
    height: auto;
    margin-bottom: 4rem;
  }

  .features-icon {
    width: 70px;
    height: 70px;
    line-height: 60px;
    font-size: 1.5rem;
    top: -35px;
  }

  .contact-us-subcontent-header h2 {
    font-size: 24px;
  }

  .contact-us-subcontent-header h2:before {
    width: 7rem;
    left: 25%;
  }

  .text-muted {
    font-size: 0.8rem;
  }

  .form-group {
    margin-top: 1rem;
  }

  .contact-submit-button {
    width: 100%;
    margin-top: 1rem;
  }

  .contact-details-container {
    padding: 2rem;
  }

  .features-info .title {
    font-size: 1rem;
  }
}
@media only screen and (min-width: 1400px) {
  .contact-main-container{
  padding-left: 9rem;
  padding-right: 9rem;
}

}